<!-- 关于我们 -->
<template>
	<div class="guanYuWoMen" id="guanYuWoMen">
		<!-- 主体 -->
		<div class="page-content">
			<h-img class="bg" :src="require('@/assets/055.png')"></h-img>
			<div class="nav-list">
				<div
					class="item"
					:class="menuItem_guanYuWoMen_item.name == erJiMenu_path ? 'active' : 'normal'"
					v-for="(menuItem_guanYuWoMen_item, index) in menuItem_guanYuWoMen.children"
					:key="index"
					@click="menuItem_guanYuWoMen_item_click(menuItem_guanYuWoMen_item, index)"
				>
					{{ menuItem_guanYuWoMen_item.name }}
				</div>
			</div>
			<!-- 关于我们 -->
			<div class="section-1" v-if="erJiMenu_path == '关于我们'">
				<div class="wrap">
					<div class="company-profile wow animate__animated animate__bounceInUp">
						<div class="title">公司简介</div>
						<div class="english">Company Profile</div>
						<div class="body-content">
							<div class="txt">{{ configListAll.companyDesc }}</div>
							<!-- <img class="pic-001" src="/src/assets/001.png" alt=""> -->
							<h-img class="pic-001 animate_scale_1_1" :src="configListAll.companyImg"></h-img>
						</div>
						<div class="statistics-list">
							<div class="item">
								<div class="container">
									<div class="txt">客户数量</div>
									<div class="num">{{ configListAll.keHuShuLiang }}</div>
								</div>
							</div>
							<div class="item">
								<div class="container">
									<div class="txt">覆盖国家和地区</div>
									<div class="num">{{ configListAll.fuGaiGuoJia }}</div>
								</div>
							</div>
							<div class="item">
								<div class="container">
									<div class="txt">合作运营商</div>
									<div class="num">{{ configListAll.heZuoYunYingShang }}</div>
								</div>
							</div>
							<div class="item">
								<div class="container">
									<div class="txt">支持全语音类别</div>
									<div class="num">{{ configListAll.quanYuYanLeiBie }}</div>
								</div>
							</div>
							<div class="item">
								<div class="container">
									<div class="txt">全国布局子公司</div>
									<div class="num">{{ configListAll.companyZigongsi }}</div>
								</div>
							</div>
						</div>
					</div>
					<div class="pic-list wow animate__animated animate__bounceInUp">
						<div class="row" v-if="configListAll.companyHj">
							<h-img class="pic animate_scale_1_1" :src="item" v-for="(item, index) in configListAll.companyHj.split(',')" :key="index"></h-img>
						</div>
					</div>
				</div>
			</div>
			<!-- 公司团队 -->
			<!-- <div class="section-2" v-if="erJiMenu_path == '公司团队'">
				<div class="wrap">
					<div class="list">
						<div class="item item-1" v-for="(item, index) in teamList" :key="index">
							<div class="pic-box">
								<h-img class="pic-black" :src="item.userAvatar"></h-img>
							</div>
							<div class="content">
								<div class="name">{{ item.userName }}</div>
								<div class="position">{{ item.position }}</div>
								<div class="description">{{ item.description }}</div>
							</div>
						</div>
					</div>
				</div>
			</div> -->
			<!-- 联系我们 -->
			<div class="section-3" v-if="erJiMenu_path == '联系我们'">
				<div class="wrap">
					<div class="left-block">
						<div class="company-name">{{ configListAll.companyName }}</div>
						<div class="english">{{ configListAll.companyNameEnglish }}</div>
						<div class="line"></div>
						<div class="tip">关注一建优采，获取更多商机信息</div>
						<div class="qr-box">
							<div class="item item-1">
								<h-img class="pic-block" :src="configListAll.website_afficial"></h-img>
								<div class="description">微信公众号</div>
							</div>
							<div class="item item-2">
								<h-img class="pic-block" :src="configListAll.website_applet"></h-img>
								<div class="description">微信小程序</div>
							</div>
							<div class="item item-3">
								<h-img class="pic-block" :src="configListAll.website_weibo"></h-img>
								<div class="description">微博</div>
							</div>
						</div>
						<div class="contact-block">
							<div class="left">
								<div class="label">全国统一咨询热线</div>
								<div class="value">{{ configListAll.companyQgrx }}</div>
								<div class="label label-3">服务时间：24小时全天服务</div>
							</div>
							<div class="right">
								<div class="label">前台电话</div>
								<div class="value">{{ configListAll.qtphone }}</div>
							</div>
						</div>
					</div>
					<div class="middle-block">
						<div class="item">
							<div class="label">地址：</div>
							<div class="content">{{ configListAll.website_address }}</div>
						</div>
						<div class="item">
							<div class="label">电话：</div>
							<div class="content">{{ configListAll.website_phone }}</div>
						</div>
						<div class="item">
							<div class="label">邮箱：</div>
							<div class="content">{{ configListAll.website_email }}</div>
						</div>
						<div class="item">
							<div class="label">邮编：</div>
							<div class="content">{{ configListAll.postcode }}</div>
						</div>
					</div>
					<div class="right-block">
						<div class="label">前台电话</div>
						<div class="value">{{ configListAll.qtphone }}</div>
						<div class="line"></div>
						<div class="label">全国统一咨询热线</div>
						<div class="value">{{ configListAll.companyQgrx }}</div>
						<div class="label label-3">服务时间：24小时全天服务</div>
					</div>
					<div class="qr-box-bottom">
						<div class="item item-1">
							<h-img class="pic-block" :src="configListAll.website_afficial"></h-img>
							<div class="description">微信公众号</div>
						</div>
						<div class="item item-2">
							<h-img class="pic-block" :src="configListAll.website_applet"></h-img>
							<div class="description">微信小程序</div>
						</div>
						<div class="item item-3">
							<h-img class="pic-block" :src="configListAll.website_weibo"></h-img>
							<div class="description">微博</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { ContentList } from '@/apis/wenZhangLanMu.js';
import { TeamList } from '@/apis/gongSiTuanDui.js';
export default {
	props: ['name'],
	data() {
		return {
			navList: this.$global.navList_for_headerOne,
			menuItem_guanYuWoMen: [],
			teamList: []
		};
	},
	computed: {
		...mapState(['configListAll'])
	},
	watch: {
		erJiMenu_path: {
			handler(val, oldval) {
				this.menuItem_guanYuWoMen = { ...this.$store.getters.getMenuItem('关于我们') };
				if (!val) {
					this.$store.commit('changeErJiMenuPath', this.menuItem_guanYuWoMen.children[0].name)
				} else if(this.menuItem_guanYuWoMen.children.some(item => val == item.name)) {
					this.$nextTick(() => {
						if (val == '关于我们') {
						} else if (val == '公司团队') {
							this.getTeamList();
						} else if (val == '联系我们') {
						}
					})
				}
			},
			deep: true,
			immediate: true
		}
	},
	mounted() {
		new WOW({
			boxClass: 'wow',
			animateClass: 'animated',
			offset: this.isMobile ? 0 : 150,
			mobile: true,
			live: false
		}).init();
	},
	methods: {
		getTeamList() {
			TeamList().then(res => {
				this.teamList = res.data;
			});
		},
		menuItem_guanYuWoMen_item_click(menuItem_guanYuWoMen_item, index) {
			if (this.erJiMenu_path == menuItem_guanYuWoMen_item.name) {
				return;
			} else {
				this.$store.commit('changeErJiMenuPath', menuItem_guanYuWoMen_item.name)
			}
		}
	}
};
</script>

<style lang="scss" scoped>
@import './guanYuWoMen.scss';
@import './guanYuWoMenMedia.scss';
</style>
